export default class ToggleCase {
  static toUpper(str) {
    return str.toString().toUpperCase()
  }

  static toLower(str) {
    return str.toString().toLowerCase()
  }

  static firstUpper(str) {
    return str.substring(0, 1).toUpperCase() + str.substring(1).toLowerCase()
  }

  static firstWordUpper(str) {
    return str.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }
}
